.sidebar {
    width: 270px;
    height: auto;
    color: white;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: 24px 0 24px 24px;
    box-sizing: border-box;
}

.sidebar-bg {
    background-color: var(--obius-primary);
    width: auto;
    border-radius: 16px;
    padding: 30px 16px 60px 16px;
    box-sizing: border-box;
    min-width: 250px;
    min-height: 600px;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 70px;
}

.obius-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nav-button {
    display: flex;
    align-items: center;
    padding: 15px;
    color: white;
    background-color: transparent;
    border: none;
    border-radius: 14px 0 14px 0;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    position: relative;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.16px;
    text-decoration: none;
    text-align: left;
}

.nav-button:hover, .active {
    background-color: white;
    color: var(--obius-primary);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.icon {
    margin-right: 10px;
    fill: white;
    transition: fill 0.3s;
}

.nav-button:hover .icon, .active .icon {
    fill: var(--obius-primary)
}

.nav-button:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
    transition: background-color 0.3s;
}

.nav-button:hover::before {
    background-color: rgba(255, 255, 255, 0.2);
}

.avatar {
    display: flex;
    align-items: center;
    margin-top: 70px;
}

.katana-sidemenu-footer {
    margin-top: 70px;
}

.avatar-image {
    width: 40px;
    height: 40px;
    background-color: grey;
    border-radius: 50%;
    margin-right: 10px;
}

.avatar-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.avatar-name {
    font-weight: bold;
}

.avatar-title {
    color: grey;
    padding-bottom: 6px;
    margin-top: 8px;
}

.avatar-title button {
    color: grey;
    background: none;
    border: 0;
    cursor: pointer;
    font-size: 14px;
}