.app {
    display: flex;
    height: 100vh;
}

.main-container {
    display: flex;
    flex: 1;
}

.main-content {
    flex: 1;
    padding: 20px;
    border-radius: 10px;
}
.dashboard-container {
    padding: 10px 20px;
}
.dashboard-section {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-title {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}

.dashboard-title h1 {
    font-size: 24px;
    margin: 0;
    text-align: left;
}

.dashboard-title span {
    font-size: 14px;
    color: #ABABAB;
    margin-top: 6px;
    text-align: left;
}

.dashboard-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row;
}

.filters-container {
    display: flex;
    justify-content: space-between;
}

.exports-container {
    display: flex;
    justify-content: end;
    margin-right: 20px;
}
