.top-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.search-bar {
    display: flex;
    align-items: center;
    /* background-color: #f0f0f0; */
    border-radius: 20px;
    padding: 5px 10px;
}

.search-bar i.material-icons {
    margin-right: 5px;
}

.action-buttons {
    display: flex;
    align-items: center;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin-right: 10px;
    color: #888;
}

.share-button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.share-button:hover {
    background-color: #0056b3;
}