.items-section {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.items-section h3 {
    font-size: 20px;
    margin: 12px 0;
}

.items-section .shopify-connected-message {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    max-width: 500px;
    margin: auto;
}

.looker {
    margin: 20px 0px;
}


.iframe-looker {
    border: none;
    width: 1000px;
    height: 800px;
}

.select-style {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.select-container {
    margin-bottom: 0px;
    padding-left: 10px;
}