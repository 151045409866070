.ag-header-cell-label {
    justify-content: center;
}

.ag-header-cell-text {
    text-align: center;
}

.ag-row-footer {
    font-weight: bold;
}
.ag-checkbox-cell {
    justify-content: center;
}
.button-items-section {
    display: flex;
}
.button-container {
    margin-right: 5px;
    margin-bottom: 15px;
}
.obius-grid-size-m {
    height: 500px;
}
.obius-grid-size-l {
    height: 750px;
}
.obius-grid-size-xl {
    height: 1000px;
}
.obius-grid-size-xxl {
    height: 1250px;
}