.chart-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
}

.chart-box {
    flex: 1;
    padding-right: 20px;
}

.chart-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}