.forecast-section {
    padding: 20px;
    height: 80vh;
    overflow-y: auto;
    text-align: left;
}

.forecast-section img {
    width: 95%;
    height: auto;
    object-fit: cover;
}

.quick-forecast-section {
    overflow: auto;
    max-height: 80vh;
    max-width: 80vw;
    width: 100%;
}

.quick-forecast-section table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.quick-forecast-section table th {
    background-color: #f8f9fa;
    font-weight: bold;
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
}

.quick-forecast-section table td {
    padding: 12px;
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
}

.quick-forecast-section table tbody tr:nth-child(odd) {
    background-color: #f8f9fa;
}

.quick-forecast-section table tbody tr:hover {
    background-color: #e9ecef;
}
.select-divider {
    margin-right: 10px;
}
